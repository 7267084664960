<template>
  <div class="fragment">
    <template
            v-if="$store.getters.getEasypostInvoices.length > 0 && $store.getters.getEasypostInvoicesLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'easypostInvoices_Id',
            'easypostInvoices_ForbiddenGoodsKeyword',
            'easypostInvoices_Status',
            'common_manage',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('easypostInvoices_Id.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_invoiceDate.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_labelDate.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_trackingCode.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_packageDisputeId.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_initiallyPaidAmount.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_adjustmentAmount.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_adjustmentReason.localization_value.value') }}</th>
            <th>{{ $t('easypostInvoices_disputeStatus.localization_value.value') }}</th>
            <th width="100%" class="hide-1400"></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getEasypostInvoices" :key="index">
            <td>
              #{{item.id}}
            </td>
            <td>
              {{ item.invoice_date | moment("DD MMM, YYYY") }}
            </td>
            <td>
              {{ item.label_date | moment("DD MMM, YYYY") }}
            </td>
            <td>
              {{item.tracking_code}}
            </td>
            <td>
              {{item.package_dispute_id}}
            </td>
            <td>
              {{item.initially_paid_amount}}
            </td>
            <td>
              {{item.adjustment_amount}}
            </td>
            <td>
              {{item.adjustment_reason}}
            </td>
            <td>
              {{item.dispute_status}}
            </td>
            <td class="hide-1400"></td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getEasypostInvoices"
                 :key="index"
            >
              <EasypostInvoicesTableMobile
                      :item="item"
                      @changeEasypostInvoicesPopup="changeEasypostInvoicesPopup(true, item.id)"
                      @deleteItem="deleteItem(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getEasypostInvoicesCommonList.next_page_url !== null && $store.getters.getEasypostInvoices.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextEasypostInvoicesPage}"
                    :count="$store.getters.getEasypostInvoicesCommonList.total - $store.getters.getEasypostInvoicesForPage * $store.getters.getEasypostInvoicesCommonList.current_page < $store.getters.getEasypostInvoicesForPage ?
                    $store.getters.getEasypostInvoicesCommonList.total - $store.getters.getEasypostInvoicesForPage * $store.getters.getEasypostInvoicesCommonList.current_page:
                    $store.getters.getEasypostInvoicesForPage"
            />
            <ExportBtn
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>

      </div>
    </template>

    <template
            v-if="$store.getters.getEasypostInvoicesLoading === false && $store.getters.getEasypostInvoices.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <EasypostInvoicesPopup
            v-if="isModalEasypostInvoicesPopup"
            :itemId="itemId"
            :type="'update'"
            @reload="$emit('reload')"
            @close="changeEasypostInvoicesPopup(false)"
    />
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import EasypostInvoicesPopup from "../../../../popups/EasypostInvoicesPopup/EasypostInvoicesPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import EasypostInvoicesTableMobile from "./EasypostInvoicesTableMobile/EasypostInvoicesTableMobile";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'

  export default {
    name: "EasypostInvoicesTable",
    components: {
      EasypostInvoicesTableMobile,
      NoResult,
      ShowMore,
      EasypostInvoicesPopup,
      ExportBtn,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: -1,
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalEasypostInvoicesPopup: false,

      }
    },

    methods: {
      changeEasypostInvoicesPopup(val, id = false) {
        if (id) {
          this.itemId = id
        }
        this.isModalEasypostInvoicesPopup = val

        if (!val) {
          this.itemId = -1
        }
      },


      deleteItem(id) {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteEasypostInvoices', id).then(response => {
            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordDeleted')
              this.$emit('reload')
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped lang="scss">
  .comment-row {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 115px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  @media (max-width: 1400px) {
    .comment-row {
      width: 100px;
    }
  }

  .table-manage-list .manager-btn {
    width: 200px;
  }

  @media (max-width: 1400px) {
    .table-manage-list .manager-btn {
      width: 100px;
    }

    .user-field-admin {
      flex-wrap: wrap;
      display: flex;
    }

    .hide-1400 {
      display: none;
    }
  }

  /*.site-table thead th:first-child,*/
  /*.site-table tbody td:first-child {*/
  /*padding-right: 0;*/
  /*}*/

  /*.site-table thead th:nth-child(2),*/
  /*.site-table tbody td:nth-child(2) {*/
  /*padding-left: 0;*/
  /*}*/

  /*.site-table thead th:last-child,*/
  /*.site-table tbody td:last-child {*/
  /*padding-right: 0;*/
  /*}*/

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  .size-24 {
    width: 24px;
    height: 24px;
    margin-top: -2px;
  }


</style>
