<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--           -->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
<!--          <MainButton class="btn-fit-content ml-3"-->
<!--                    :value="'Add Keyword'"-->
<!--                    :ico="'ico'"-->
<!--                    @click.native="changeEasypostInvoicesPopup(true)"-->
<!--        >-->
<!--          <template slot="ico">-->
<!--            <PlusIconSVG class="btn-icon-plus"/>-->
<!--          </template>-->
<!--        </MainButton>-->
      </div>
    </div>

    <EasypostInvoicesPopup
            v-if="isModalEasypostInvoicesPopup"
            :type="'create'"
            @reload="$emit('reload')"
            @close="changeEasypostInvoicesPopup(false)"
    />


  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import EasypostInvoicesPopup from "../../../../popups/EasypostInvoicesPopup/EasypostInvoicesPopup";


  export default {
    name: "EasypostInvoicesHead",
    components: {
      EasypostInvoicesPopup,
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{

        isModalEasypostInvoicesPopup: false,
      }
    },

    methods: {

      changeEasypostInvoicesPopup(val, id = false) {
        if(id){
          this.orderId = id
        }
        this.isModalEasypostInvoicesPopup = val

        if(!val){
          this.orderId = -1
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

