<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          #{{item.id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_invoiceDate.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.invoice_date | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_labelDate.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.label_date | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_trackingCode.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.tracking_code}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_packageDisputeId.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.package_dispute_id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_initiallyPaidAmount.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.initially_paid_amount}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_adjustmentAmount.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.adjustment_amount}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_adjustmentReason.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.adjustment_reason}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('easypostInvoices_disputeStatus.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.dispute_status}}
            </div>
          </div>
        </div>
<!--        <div class="table-card__item-btn">-->
<!--          <div class="table-card__item-btn-i">-->
<!--            <LinkButton-->
<!--                    :value="$t('common_edit.localization_value.value')"-->
<!--                    :type="'edit'"-->
<!--                    @click.native="$emit('changeEasypostInvoicesPopup')"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="table-card__item-btn-i">-->
<!--            <LinkButton-->
<!--                    :value="$t('common_delete.localization_value.value')"-->
<!--                    :type="'delete'"-->
<!--                    @click.native="$emit('deleteItem')"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "EasypostInvoicesTableMobile",
    components: {},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
