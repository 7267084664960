<template>
  <div class="fragment">
    <EasypostInvoicesTableAdmin
        v-if="isAdmin"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
        @downloadFiles="downloadFiles"
    />


  </div>

</template>

<script>
  import EasypostInvoicesTableAdmin from "./EasypostInvoicesAdmin/EasypostInvoicesAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "EasypostInvoicesTable",
    components: {
      EasypostInvoicesTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getEasypostInvoicesForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterTrackingCode: '',
        filterPackageDisputeId: '',
        filterInitiallyPaidAmount: '',
        filterAdjustmentReason: '',
        filterDisputeStatus: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          trackingCode: this.$route.query.trackingCode,
          packageDisputeId: this.$route.query.packageDisputeId,
          initiallyPaidAmount: this.$route.query.initiallyPaidAmount,
          adjustmentReason: this.$route.query.adjustmentReason,
          disputeStatus: this.$route.query.disputeStatus,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextEasypostInvoicesPage', true)
        this.$store.dispatch('fetchEasypostInvoices', url).then(() => {
          this.$store.commit('setNextEasypostInvoicesPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'id',
            filterTrackingCode: 'tracking_code',
            filterPackageDisputeId: 'package_dispute_id',
            filterInitiallyPaidAmount: 'initially_paid_amount',
            filterAdjustmentReason: 'adjustment_reason',
            filterDisputeStatus: 'dispute_status',
          },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)


        this.exportTableFiles(url, type, 'getExportEasypostInvoices', 'Easypost Invoices')

        // this.$store.dispatch('getExportConsolidationOrders', {filter: url, exportType: type}).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //     const blob = new Blob([response.data], {type: `application/${type}`})
        //     const link = document.createElement('a')
        //     link.href = URL.createObjectURL(blob)
        //     link.download = `Easypost Invoices.${type}`
        //     link.click()
        //     URL.revokeObjectURL(link.href)
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('error', 'common_notificationUndefinedError')
        //     }, 200)
        //   }
        // }).catch(error => this.createErrorLog(error))
      },

    },

  }
</script>

<style scoped>

</style>
